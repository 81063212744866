<template>
  <span class="move-streets" v-if="hasPerm('people.change_street')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      :ok-disabled="formInvalid"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Changer"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-house-chimney"></i>
          Changer <counter-label :counter="streets.length" label="rue"></counter-label> de quartier
        </b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div v-if="streets && streets.length" style="padding: 5px; background: #ccc; margin-bottom: 20px">
        Sélectionnez le quartier dans lequel affecter
        <span v-if="streets.length > 1">
          les {{ streets.length }} rues sélectionnées
        </span>
        <span v-else>
          {{ streets[0].name }}
        </span>
      </div>
      <b-row>
        <b-col>
          <b-form-group label="Niveau actuel">
            <b-input type="text" disabled v-if="currentGroup" :value="currentGroup.name"></b-input>
            <b-input type="text" disabled v-else value="Quartier non défini"></b-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            id="selectedGroup-gr"
            label="Changer vers"
            label-for="selectedGroup"
          >
            <b-form-select
              v-model="selectedGroup"
              id="selectedGroup"
            >
            <b-form-select-option
              :value="elt"
              v-for="elt in groups"
              :key="elt.id"
            >
              {{ elt.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'

export default {
  name: 'MoveStreetsModal',
  components: { CounterLabel, },
  mixins: [BackendMixin],
  props: {
    currentGroup: Object,
    groups: Array,
    streets: Array,
  },
  data() {
    return {
      errorText: '',
      selectedGroup: null,
    }
  },
  watch: {
    groups: function() {
      this.init()
    },
    street: function() {
      if (this.street) {
        this.init()
      }
    },
  },
  mounted() {
    this.init()
  },
  computed: {
    formInvalid() {
      return (this.streets.length === 0) || (this.selectedGroup === null)
    },
    modalId() {
      return 'bv-modal-move-street'
    },
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
    },
    async onSave() {
      this.errorText = ''
      if (!this.formInvalid) {
        let url = '/api/people/change-streets/'
        const backendApi = new BackendApi('post', url)
        try {
          const data = {
            'group': this.selectedGroup.id,
            'streets': this.streets.map(elt => elt.id),
          }
          await backendApi.callApi(data)
          let text = ''
          if (this.streets.length === 1) {
            text = 'La rue a été placée dans le quartier ' + this.selectedGroup.name
          } else {
            text = 'Les rues ont été placées dans le quartier ' + this.selectedGroup.name
          }
          await this.addSuccess(text)
          this.$bvModal.hide(this.modalId)
          this.$emit('done', this.selectedGroup)
          this.selectedGroup = null
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
}
</script>

<style scoped>
</style>
